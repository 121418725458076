import {collection, getDocs} from "firebase/firestore";
import {db} from "@/firebase";
import {parksData} from "../../json/parks";
import {eventData} from "../../json/events2";
import {getStates, slugify} from "@/lib/util";

export async function getAllEvents() {
    const response = await getDocs(collection(db, "events"))
    let events = JSON.parse(JSON.stringify(response.docs))

    let allEvents = [];
    events.map((event) => {
        allEvents.push(event._document.data.value.mapValue.fields)
    })

    return allEvents
}

export function getParkNameSelectOptions() {
    const parkNameOptions = []
    parksData && parksData.forEach((park) => parkNameOptions.push({'value': slugify(park.fullName), 'label': park.fullName}))
    return parkNameOptions
}

export function getParkNames() {
    let parkNames = []
    parksData.map((park) => parkNames.push(park.fullName))
    return parkNames
}

export function getParkFromParkCode(parkCode) {
    return parksData.filter((park) => park.parkCode === parkCode)
}

export function getParkFromFullName(parkFullName) {
    return parksData.filter((park) => park.fullName === parkFullName)
}

export function statesAndParkCodes() {
    let parkStatesAndCode = []
    parksData.map((park) => parkStatesAndCode.push({'parkCode': park.parkCode, 'state': park.states }))
    return parkStatesAndCode
}

export function getStateAbbr(stateFullName) {
    const states = getStates()
    let statesWithLabel = states.filter(state => state.label === stateFullName)
    return statesWithLabel[0].value
}

export function getAllParkCodesForState(stateAbbr) {
    const parkStatesAndParkCodes = statesAndParkCodes()
    let parkCodes = []
    parkStatesAndParkCodes.map((item) => item.state.includes(stateAbbr) ? parkCodes.push(item.parkCode) : null)
    return parkCodes
}

export function getEventsByPark(park) {
    return eventData.filter((event) => park.parkCode === event.sitecode)
}

export function getEventsAndSortByDate(events, ignoreClosed = false) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight

    // Filter out past events based on `dateend` and optionally exclude "Closed" events
    const filteredEvents = events.filter((event) => {
        const eventEndDate = new Date(event.dateend || event.datestart || event.date);
        eventEndDate.setHours(0, 0, 0, 0);

        // Check if the event should be excluded based on "Closed" in title
        const isClosed = ignoreClosed && event.title?.toLowerCase().includes("closed");

        return eventEndDate >= today && !isClosed;
    });

    // Sort events with prioritization
    filteredEvents.sort((a, z) => {
        // Check if events are recurring
        const isARecurring = !!a.recurrencedatestart;
        const isZRecurring = !!z.recurrencedatestart;

        // Prioritize non-recurring events
        if (isARecurring !== isZRecurring) {
            return isARecurring ? 1 : -1;
        }

        // Sort by date fields for non-recurring or recurring events
        const aDate = new Date(a.date || a.datestart || a.recurrencedatestart);
        const zDate = new Date(z.date || z.datestart || z.recurrencedatestart);

        return aDate - zDate;
    });

    return filteredEvents;
}

