import Select from 'react-select'
export default function TypeaheadInput({options, onChange, isClearable, placeholder, styles, className, ...props}) {
    let classNames = className ? className : 'my-5';
    let selectStyles = styles ? styles : {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderWidth: '0',
            boxShadow: 'none',
            borderRadius: '10px',
            height: '50px',
        }),
        menu: (styles, { baseStyles, state }) => ({
            ...styles,
            borderWidth: '0',
            borderRadius: '10px',
            backgroundColor: 'white'
        }),
        menuList: (styles, { baseStyles, state }) => ({
            ...styles,
            borderWidth: '0',
            borderRadius: '10px',
        }),
        valueContainer: (styles, { baseStyles, state }) => ({
            ...styles,
            paddingLeft: '10px'
        }),
        singleValue: (styles, { baseStyles, state }) => ({
            ...styles,
            fontSize: '15px',
            paddingLeft: '0'
        }),
        option: (styles, { isFocused }) => ({
            borderWidth: '0',
            padding: '12px 16px 10px',
            fontSize: '14px',
            backgroundColor: isFocused ? '#fff5ea' : 'inherit',
            ':hover': {
                backgroundColor: '#fff5ea',
                cursor: 'pointer'
            }})
    }

    return (
        <Select options={options} onChange={onChange} defaultOptions isClearable={true}
                placeholder={placeholder} {...props}
                className={classNames}
                styles={selectStyles}
                aria-label={placeholder || 'Input'}
        />
    )
}